import pick from 'lodash/pick';

const getRandomCode = function (name) {
  return `${name}-${Math.floor(Math.random() * 1001)}`;
};

const getObjectWithoutEmptyFields = function (object) {
  const resultData = {};

  Object.entries(object).forEach(([key, value]) => {
    if (value) {
      resultData[key] = value;
    }
  });

  return resultData;
};

const getObjectByTargetFields = (object, fields) => pick(object, fields);

const checkErrors = function (errorResponse, setErrors) {
  if (!setErrors || !errorResponse) return;

  if (typeof errorResponse.errors !== 'object') {
    return;
  }

  Object.entries(errorResponse.errors).forEach(([fieldName, message]) => {
    setErrors({ [fieldName]: [message] });
  });
};

export default () => ({
  getRandomCode,
  getObjectWithoutEmptyFields,
  getObjectByTargetFields,
  checkErrors,
});
