<script setup>
import { useVModel } from '@vueuse/core';
import { Field as VeeField } from 'vee-validate';

import useForm from '@/composables/useForm';
import { computed, nextTick, ref, onMounted } from '#imports';

const formComposable = useForm();

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  autoHeight: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: 1000,
  },
  name: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['update:modelValue', 'blur']);
const localVModel = useVModel(props, 'modelValue', emit);

const focused = ref(false);
const textareaItem = ref(false);

function setFocus(value) {
  focused.value = Boolean(value);
}

//<editor-fold desc="Auto height">
const contentHeight = ref(0);

function setTextareaHeight() {
  if (!props.autoHeight) return;

  contentHeight.value = 0;

  nextTick(() => {
    contentHeight.value = textareaItem.value?.scrollHeight;
  });
}

//</editor-fold>

//<editor-fold desc="Availability">
const randomId = ref();
const randomErrorId = ref();
const ariaDescribedby = ref();

onMounted(() => {
  randomId.value = formComposable.getRandomCode('input');
  randomErrorId.value = formComposable.getRandomCode('error');
  ariaDescribedby.value = randomErrorId.value;
});

const isAriaInvalid = computed(() => Boolean(props.showErrors));
//</editor-fold>
</script>

<template>
  <VeeField
    v-slot="{ field, errorMessage }"
    v-model="localVModel"
    :name="name"
    as="div"
  >
    <div
      class="ui-textarea"
      :class="{
        'ui-textarea--inner-indent': showErrors,
        'ui-textarea--disabled': isDisabled,
        'ui-textarea--error': !!errorMessage,
        'ui-textarea--active': modelValue,
        'ui-textarea--focused': focused,
      }"
    >
      <div class="ui-textarea__header">
        <label v-show="label" :for="randomId" class="ui-textarea__label">
          <span class="ui-textarea__font ui-textarea__font--label">
            {{ label }}
          </span>
        </label>
      </div>

      <div class="ui-textarea__body">
        <textarea
          :id="randomId"
          v-bind="field"
          ref="textareaItem"
          :placeholder="placeholder"
          :maxlength="maxLength"
          class="ui-textarea__field"
          :name="name"
          autocomplete="off"
          :required="isRequired"
          :disabled="isDisabled"
          :aria-describedby="ariaDescribedby"
          :aria-required="isRequired"
          :aria-invalid="isAriaInvalid"
          @input="setTextareaHeight"
          @focus="setFocus(true)"
          @blur="setFocus(false)"
        />
      </div>

      <span
        v-show="showErrors && errorMessage"
        class="ui-textarea__footer"
        :title="errorMessage"
      >
        <span
          :id="randomErrorId"
          aria-live="assertive"
          class="ui-textarea__font ui-textarea__font--error"
        >
          {{ errorMessage }}
        </span>
      </span>
    </div>
  </VeeField>
</template>

<style scoped lang="scss">
.ui-textarea {
  $parent: &;
  $font-family: $font-family-default;
  $color-basis: $color-black-100;
  $color-label: $color-black-130;
  $color-label-active: $color-black-150;
  $color-placeholder: $color-black-100;
  $color-error: $color-red-100;
  $color-border: $color-grey-200;
  $color-border-active: $color-grey-300;
  $color-hover: $color-black-100;
  $color-bg: $color-white;
  $inner-indent: 21;

  $font-size: 14;

  --height: #{em(137, $font-size)};

  position: relative;
  font-family: $font-family;
  color: $color-basis;

  @include hover {
    #{$parent} {
      &__field {
        border-color: $color-border-active;
      }
    }
  }

  &__font {
    &--label {
      font-size: em(14);
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transition: font-size 0.3s;

      @include media-breakpoint-down(lg) {
        font-size: em(10);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(14);
      }
    }

    &--error {
      @include text-overflow;

      font-size: em(10);
      font-weight: 400;
      line-height: 1.2;
      color: $color-error;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      @include media-breakpoint-down(lg) {
        font-size: em(8);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(10);
      }
    }
  }

  &__header {
  }

  &__body {
    position: relative;
    display: block;
    background: transparent;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 1px;
      top: 1px;
      right: 1px;
      height: em(15);
      border-radius: em(8);
      background-color: $color-bg;
    }
  }

  &__footer {
    position: absolute;
    bottom: em(2);
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
  }

  &__label {
    position: absolute;
    top: em(4);
    left: em(21);
    z-index: 1;
    display: block;
    color: $color-label;
    pointer-events: none;
    touch-action: none;
    cursor: auto;
    transition: all 0.3s;
    transform: translateY(em(13));

    @include media-breakpoint-down(lg) {
      top: 0;
      left: em(16);
    }

    @include media-breakpoint-down(sm) {
      top: em(6);
      left: em(21);
    }
  }

  &__field {
    @include hide-scroll;

    width: 100%;
    height: var(--height);
    padding: em(23, $font-size) em(20, $font-size);
    font-size: em($font-size);
    font-weight: 400;
    color: $color-basis;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    line-height: 1.3;
    resize: none;
    border: none;
    border-bottom: 1px solid $color-border;
    border-radius: 0;
    transition: all 0.3s;

    @include media-breakpoint-down(lg) {
      font-size: em(10);
      padding: em(23, 10) em(15, 10);
    }

    @include media-breakpoint-down(sm) {
      font-size: em($font-size);
      padding: em(23, $font-size) em(20, $font-size);
    }

    &:focus,
    &:active {
    }

    &::placeholder {
      color: $color-placeholder;
    }
  }

  &--error {
    #{$parent} {
      &__font--error {
        color: $color-error;
      }

      &__field {
        border-color: $color-error;
      }
    }
  }

  &--inner-indent {
    padding-bottom: em($inner-indent);

    @include media-breakpoint-down(lg) {
      padding-bottom: em(15);
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: em($inner-indent);
    }
  }

  &--focused {
    #{$parent} {
      &__font {
        &--label {
          font-size: em(10);
          color: $color-label-active;

          @include media-breakpoint-down(lg) {
            font-size: em(8);
          }

          @include media-breakpoint-down(sm) {
            font-size: em(10);
          }
        }
      }

      &__field {
        border-color: $color-border-active;
      }

      &__label {
        transform: translateY(em(0));
        pointer-events: auto;
        touch-action: auto;
      }
    }
  }

  &--active {
    #{$parent} {
      &__font {
        &--label {
          font-size: em(10);
          color: $color-label-active;

          @include media-breakpoint-down(lg) {
            font-size: em(8);
          }

          @include media-breakpoint-down(sm) {
            font-size: em(10);
          }
        }
      }

      &__field {
        border-color: $color-border-active;
      }

      &__label {
        transform: translateY(em(0));
        pointer-events: auto;
        touch-action: auto;
      }
    }
  }

  &--disabled {
    #{$parent} {
      &__field {
        &:focus,
        &:active {
          &::placeholder {
          }
        }

        @include hover {
        }
      }
    }
  }
}
</style>
