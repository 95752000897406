import { defineStore } from 'pinia';
import { useRequest } from '@/composables/useRequest';
import { serialize } from 'object-to-formdata';
export const useFeedbackStore = defineStore({
  id: 'feedback',
  state: () => ({}),
  getters: {},
  actions: {
    sendFeedback(data = {}) {
      const formData = serialize(data, {
        indices: true,
        booleansAsIntegers: true,
        nullsAsUndefineds: true,
      });

      return useRequest('/feedback', {
        method: 'POST',
        body: formData,
      });
    },
  },
});
