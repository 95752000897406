<script setup>
import { useNuxtApp } from '#imports';

const { $tp } = useNuxtApp();

const props = defineProps({
  heading: {
    type: String,
    default: undefined,
  },
  title: {
    type: String,
    default: undefined,
  },
  description: {
    type: String,
    default: undefined,
  },
  theme: {
    type: String,
    default: undefined,
    validator(theme) {
      return [''].includes(theme);
    },
  },
});
</script>
<template>
  <div
    class="form-wrapper"
    :class="{
      [`form-wrapper--theme--${props.theme}`]: props.theme,
    }"
  >
    <div v-if="props.heading" class="form-wrapper__heading">
      <h3
        class="form-wrapper__font form-wrapper__font--heading"
        v-html="$tp(props.heading)"
      />
    </div>

    <div v-if="props.title" class="form-wrapper__title">
      <h4
        class="form-wrapper__font form-wrapper__font--title"
        v-html="$tp(props.title)"
      />
    </div>

    <div v-if="props.description" class="form-wrapper__description">
      <p
        class="form-wrapper__font form-wrapper__font--description"
        v-html="$tp(props.description)"
      />
    </div>

    <slot name="content" />
  </div>
</template>
<style scoped lang="scss">
.form-wrapper {
  $parent: &;

  width: em(672);
  padding: em(72) em(88) em(56);
  background-color: $color-white;

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: em(72) em(30) em(56);
  }

  &__font {
    &--heading {
      @include h4;

      color: $color-black-100;

      @include media-breakpoint-down(lg) {
        font-size: em(20);
      }

      @include media-breakpoint-down(sm) {
        @include h4-mob;
      }
    }

    &--title {
      @include subtitle-m;

      color: $color-black-100;

      @include media-breakpoint-down(lg) {
        font-size: em(11);
      }

      @include media-breakpoint-down(sm) {
        @include subtitle-m-mob;
      }
    }

    &--description {
      @include text-s;

      color: $color-black-100;

      &:deep(strong) {
        font-weight: 700;
      }
    }
  }

  &__heading {
    margin-bottom: em(32);

    @include media-breakpoint-down(lg) {
      margin-bottom: em(23);
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: em(24);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: em(16);

      @include media-breakpoint-down(sm) {
        margin-bottom: em(12);
      }
    }
  }

  &__description {
    &:not(:last-child) {
      margin-bottom: em(16);

      @include media-breakpoint-down(sm) {
        margin-bottom: em(12);
      }
    }
  }
}
</style>
