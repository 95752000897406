import { useModal } from 'vue-final-modal';
import { defineAsyncComponent } from '#imports';

export const useMessagePopup = () => {
  const openMessagePopup = (message, isSuccess = true) => {
    const { open, close } = useModal({
      modalId: 'message',
      component: defineAsyncComponent(
        () => import('@/components/popups/Message.vue'),
      ),
      attrs: {
        text: message,
        isSuccess: isSuccess,
        closeModal: () => {
          close();
        },
      },
    });

    open();
  };

  return {
    openMessagePopup,
  };
};
