<script setup>
import { useDropZone } from '@vueuse/core';

import useForm from '@/composables/useForm';
import { Field as VeeField } from 'vee-validate';
import useFile from '@/composables/useFile';
import { ref, onMounted } from '#imports';
import CIcon from '@/components/common/CIcon.vue';

const formComposable = useForm();

const fileComposable = useFile();

const props = defineProps({
  modelValue: {
    type: Array,
    default: undefined,
  },
  label: {
    type: String,
    default: '',
  },
  labelMobile: {
    type: String,
    default: '',
  },
  hint: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  accept: {
    type: String,
    default: undefined,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const refDropZone = ref();
const fileInputItem = ref(null);

useDropZone(refDropZone, handleDropZoneFiles);

function handleFiles(e) {
  const filesConvertedInArray = Array.from(e.target.files);

  imageHandler(filesConvertedInArray);
}

function handleDropZoneFiles(files) {
  const inputEl = fileInputItem.value;

  inputEl.files = fileComposable.getDataTransfer(files);

  imageHandler(files);
}

function imageHandler(files) {
  const filesConvertedInArray = Array.from(files);

  emit('update:modelValue', filesConvertedInArray);

  changeImageHandler(filesConvertedInArray);
}

//<editor-fold desc="File delete">
function deleteFile(index) {
  const inputEl = fileInputItem.value;
  const inputData = inputEl.files;

  inputEl.files = fileComposable.getDataTransfer(inputData, index);

  const inputDataConvertedInArray = Array.from(inputEl.files);

  const updateData = inputDataConvertedInArray?.length
    ? inputDataConvertedInArray
    : null;

  emit('update:modelValue', updateData);

  changeImageHandler(inputDataConvertedInArray);
}

//</editor-fold>

//<editor-fold desc="Availability">
const randomId = ref();
const randomErrorId = ref();
const ariaDescribedby = ref();

onMounted(() => {
  randomId.value = formComposable.getRandomCode('input');
  randomErrorId.value = formComposable.getRandomCode('error');
  ariaDescribedby.value = randomErrorId.value;
});
//</editor-fold>

//<editor-fold desc="File preview">
const filesPreview = ref([]);

async function changeImageHandler(files) {
  const filesBase64 = [];
  const promiseArray = [];

  files.forEach((file) => {
    promiseArray.push(
      fileComposable.readFileAsync(file, 'readAsDataURL').then((result) => {
        filesBase64.push(result);
      }),
    );
  });

  await Promise.all(promiseArray);

  filesPreview.value = filesBase64;
}

function getNameByType(type) {
  if (!type) return;

  return fileComposable.fileTypesReverse?.[type];
}

//</editor-fold>
</script>

<template>
  <VeeField
    v-slot="{ errorMessage }"
    :name="name"
    :model-value="modelValue"
    type="file"
    as="div"
  >
    <div
      class="ui-file"
      :class="{
        'ui-file--inner-indent': showErrors,
        'ui-file--disabled': isDisabled,
        'ui-file--error': !!errorMessage,
        'ui-file--active': modelValue?.length > 0,
      }"
    >
      <div class="ui-file__body">
        <div ref="refDropZone" class="ui-file__drop-zone">
          <div class="ui-file__label">
            <span
              class="ui-file__font ui-file__font--label"
              v-html="props.label"
            />
          </div>
          <div class="ui-file__label ui-file__label--mobile">
            <span
              class="ui-file__font ui-file__font--label"
              v-html="props.labelMobile || props.label"
            />
          </div>

          <label class="ui-file__field">
            <input
              :id="randomId"
              ref="fileInputItem"
              class="ui-file__input"
              type="file"
              :name="name"
              :accept="accept"
              :aria-describedby="ariaDescribedby"
              :aria-required="isRequired"
              multiple
              @input="handleFiles"
            />

            <span role="button" class="ui-file__button ui-file__button--select">
              <span class="ui-file__font ui-file__font--select">
                Выбрать файл
              </span>
            </span>
          </label>
        </div>

        <div class="ui-file__footer">
          <transition name="fade" mode="out-in">
            <div v-if="filesPreview" class="ui-file__preview">
              <div
                v-for="(img, index) in modelValue"
                :key="index"
                class="ui-file__preview-item"
              >
                <div class="ui-file__preview-item-type">
                  <span class="ui-file__font ui-file__font--preview-item-type">
                    {{ getNameByType(img.type) }}
                  </span>
                </div>

                <div class="ui-file__preview-item-info">
                  <div class="ui-file__preview-item-name">
                    <p class="ui-file__font ui-file__font--preview-item-name">
                      {{ fileComposable.truncateFileName(img.name, 26) }}
                    </p>
                  </div>

                  <div class="ui-file__preview-item-size">
                    <p class="ui-file__font ui-file__font--preview-item-size">
                      {{ fileComposable.getMbFromBites(img.size) }} mb
                    </p>
                  </div>
                </div>

                <button
                  type="button"
                  class="ui-file__button ui-file__button--delete"
                  @click="deleteFile(index)"
                >
                  <CIcon
                    name="delete-file"
                    aria-hidden="true"
                    class="ui-file__icon ui-file__icon--delete"
                  />
                </button>
              </div>
            </div>
          </transition>

          <div class="ui-file__hint">
            <span class="ui-file__font ui-file__font--hint">
              {{ props.hint }}
            </span>
          </div>

          <div
            v-show="showErrors && errorMessage"
            class="ui-file__error-message"
            :title="errorMessage"
          >
            <span
              :id="randomErrorId"
              aria-live="assertive"
              class="ui-file__font ui-file__font--error"
            >
              {{ errorMessage }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </VeeField>
</template>

<style scoped lang="scss">
.ui-file {
  $parent: &;
  $font-family: $font-family-default;
  $color-basis: $color-black-100;
  $color-label: $color-black-100;
  $color-hint: $color-black-100;
  $color-error: $color-red-100;
  $color-border: $color-grey-115;
  $inner-indent: 21;

  position: relative;
  font-family: $font-family;
  color: $color-basis;

  &__font {
    &--label {
      //@include text-overflow;

      @include subtitle-s;
    }

    &--hint {
      //@include text-overflow;

      font-size: em(12);
      line-height: 1.6;
      color: $color-hint;
      letter-spacing: 0.05em;
    }

    &--error {
      @include text-overflow;

      font-size: em(10);
      font-weight: 400;
      line-height: 1.2;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      @include media-breakpoint-down(lg) {
        font-size: em(8);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(10);
      }
    }

    &--select {
      font-size: em(12);
      font-weight: 400;
      line-height: 1.6;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    &--preview-item-type {
      font-size: em(10);
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }

    &--preview-item-name {
      font-size: em(10);
      font-weight: 400;
      line-height: 1.8;
      color: $color-black-100;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }

    &--preview-item-size {
      font-size: em(10);
      font-weight: 400;
      line-height: 1.8;
      color: $color-black-100;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }
  }

  &__icon {
    flex-shrink: 0;
    transition: all 0.3s;

    &--delete {
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    max-width: em(284);
    color: $color-label;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &--mobile {
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }
  }

  &__body {
    width: 100%;
  }

  &__drop-zone {
    display: flex;
    flex-direction: column;
    gap: em(16);
    align-items: center;
    justify-content: center;
    padding: em(25);
    margin-bottom: em(8);
    text-align: center;
    background-color: $color-grey-115;
    backdrop-filter: blur(10px);
    border: 1px dashed $color-border;
    border-radius: 0;
    transition: all 0.3s;
  }

  &__preview {
    display: flex;
    flex-direction: column;
    gap: em(12);
  }

  &__preview-item {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: em(8) 0;
    background-color: $color-white;
  }

  &__preview-item-type {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: em(32);
    height: em(32);
    margin-right: em(12);
    overflow: hidden;
    color: $color-white;
    background-color: $color-red-100;
    border-radius: em(4);
  }

  &__preview-item-name {
    margin-bottom: em(2);
  }

  &__error-message {
    position: absolute;
    bottom: em(2);
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    color: $color-error;
    text-align: left;
  }

  &__field {
    display: inline-flex;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.3s;
  }

  &__footer {
  }

  &__input {
    @include visually-hidden;
  }

  &__button {
    flex-shrink: 0;
    transition: all 0.3s;

    &--select {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-height: em(34);
      padding: em(2) em(24) em(4);
      color: $color-basis;
      border: 1px solid $color-basis;
      border-radius: em(44);

      @include hover {
        background-color: rgba($color-grey-100, 0.15);
      }
    }

    &--delete {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: em(24);
      height: em(24);
      margin-left: auto;
      color: $color-grey-300;

      @include hover {
        color: $color-basis;
      }
    }
  }

  &__append {
    position: absolute;
    top: 50%;
    display: flex;
    line-height: 1;
    cursor: pointer;
    transform: translateY(-50%);

    &--before {
      left: em(15);
    }

    &--after {
      right: em(15);
    }
  }

  &--error {
    #{$parent} {
      &__font--error {
        color: $color-error;
      }

      &__field {
      }
    }
  }

  &--inner-indent {
    padding-bottom: em($inner-indent);

    @include media-breakpoint-down(lg) {
      padding-bottom: em(15);
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: em($inner-indent);
    }
  }

  &--disabled {
    #{$parent} {
      &__field {
        @include hover {
        }

        &:focus,
        &:active {
          &::placeholder {
          }
        }
      }
    }
  }

  &--active {
    #{$parent} {
      &__drop-zone {
      }
    }
  }
}
</style>
